import { createContext, useContext, memo, useEffect } from 'react'
import { useSearchParams } from "react-router-dom"
import { trackAnalytics } from 'actions'

const ScreeningContext = createContext()

export const ScreeningProvider = memo(({children}) => {

	const [searchParams, ] = useSearchParams()
	const gclid = searchParams.get('gclid') || null
	const fbclid = searchParams.get('fbclid') || null
	const source = searchParams.get('utm_source') || null
	const case_type_id = searchParams.get('cid') || null

	useEffect(() => {
		trackAnalytics({
			'event': 'page_view',
			'action': 'view',
			gclid,
			fbclid,
			source,
			case_type_id,
		})
	})

	const trackEvent = (event) => {
		trackAnalytics({...event, gclid, fbclid, source, case_type_id })
	}

	return (
		<ScreeningContext.Provider value={{trackEvent}}>
			{children}
		</ScreeningContext.Provider>
	)
})

export const useScreening = () => useContext(ScreeningContext)