import { memo, Fragment, useRef } from 'react'
import Select from 'react-select'

const Question = (props) => {
	const { id, title, options, setFieldValue, multiselect } = props
	const selRef = useRef()
	/*if(selRef.current) {
		console.log(selRef.current.props)
		console.log(selRef.current.props.value)
	}*/

	// Custom styles for the Select component
	const customStyles = {
		control: (provided) => ({
      		...provided,
      		border: '0', // Border color
      		borderRadius: '4px',
      		boxShadow: 'none',
      		Padding:"20px",
      		backgroundColor: 'rgb(255 255 255 / 15%)', 
	    }),
	    menu: (provided) => ({
      		...provided,
      		backgroundColor: '#fff', 
      		Padding:"20px"
	    }),
	    option: (provided, state) => ({
      		...provided,
      		backgroundColor: state.isFocused ? 'rgb(83 86 87 / 10%)' : 'transparent',
      		border: state.isFocused ? '1px solid transparent' : '1px solid transparent', // Border when option is focused
      		color: state.isSelected ? '#333' : '#333', 
  			fontSize: state.isSelected ? '16px' : '16px', 
      		borderRadius: state.isSelected ? '8px' :'8px', 
	    }),
	    placeholder: (provided) => ({
      		...provided,
      		color: "white",
      		fontSize: "14px",
    	}),

	}

	const isCheckedBtn = (value) => value === props.values[`question-${id}`]

	const showButtons = () => {
		return (
			<div className='flex gap-3'>
				<div className='flex gap-3'>
					{options.map(item => {
						return (
							<Fragment key={`${item.value}-btn`}>
								<button  type="button" className={`font-normal text-base leading-5 py-2.5 px-4 rounded-[13px] ${isCheckedBtn(item.value) ? 'bg-white text-[#202020]' : 'next-btn text-white'}`} onClick={() => setFieldValue(`question-${id}`, item.value)}>{item.name}</button>
							</Fragment>	
						)
					})}
				</div>
			</div>	
		)
	}

	const setSelectedOption = (item) => {
		if(multiselect) {
			const values = item.map(item => item.value)
			setFieldValue(`question-${id}`, values)
		} else {
			setFieldValue(`question-${id}`, item.value)
		}
	}

	const getOptionLabel = (item) => item.name
	const getDefaultValue = (value) => {
		if(multiselect) {
			return options.filter(item => value.includes(item.value))
		} else {
			return options.filter(item => item.value === value).pop()
		}
	}

	const showDropdown = () => {
		return (
			<div className="App">
				<div className='form_select_2'>
					<Select ref={selRef} isMulti={multiselect} name={`question-${id}`} defaultValue={getDefaultValue(props.values[`question-${id}`])} options={options} getOptionLabel={getOptionLabel} onChange={setSelectedOption} className="focus:outline-none" styles={customStyles} />
				</div>
			</div>
		)
	}

	return (
		<>
			<div>
				<div className='block'>
					<p className='font-normal text-[20px] text-base leading-7 mb-3 text-white'>{title}</p>

					{!multiselect &&
						<>
							{options.length <= 3 &&
								showButtons()
							}

							{options.length > 3 &&
								showDropdown()
							}
						</>
					}
					{multiselect &&
						showDropdown()
					}
				</div>
			</div>
		</>
	)
}

export default memo(Question)
