import { memo } from 'react'
import FormImg from 'assets/v4/img/form-img1.svg'
import FormImg2 from 'assets/v4/img/form-img2.svg'
import FormImg3 from 'assets/v4/img/form-img3.svg'
import FormImg4 from 'assets/v4/img/form-img4.svg'
import FormMobileImg from "assets/v4/img/form-mobileimg1.svg"
import FormMobileImg2 from "assets/v4/img/form-mobileimg2.svg"
import FormMobileImg3 from "assets/v4/img/form-mobileimg3.svg"
import FormMobileImg4 from "assets/v4/img/form-mobileimg4.svg"

const Badges = (props) => {

	const badges = [
		{
	    	desktopSrc: FormImg,
    		mobileSrc: FormMobileImg,
	    	alt: "logo-img",
	  	},
	  	{
	    	desktopSrc: FormImg2,
	    	mobileSrc: FormMobileImg2,
	    	alt: "logo-img",
	  	},
	  	{
		    desktopSrc: FormImg3,
		    mobileSrc: FormMobileImg3,
		    alt: "logo-img",
	  	},
	  	{
		    desktopSrc: FormImg4,
		    mobileSrc: FormMobileImg4,
		    alt: "logo-img",
	  	},
	]

	return (
		<>
			<div className="mt-20 mb-8 p-0 relative max-w-[650px] mx-auto">
				<ul className="flex items-center imgList-wrap  gap-5 logoBox justify-between sm:justify-center">
					{badges.map((item, index) => (
						<li key={index} className="sm:mb-0 min-w-[110px] text-left  sm:min-w-auto  sm:w-auto">
							{/*<img src={item.desktopSrc} className="hidden md:block max-w-full mx-auto w-auto h-auto" alt={item.alt} />
							<img src={item.mobileSrc} className="block md:hidden max-w-[150px] w-auto h-auto" alt={item.alt} />*/}
							<img src={item.desktopSrc} className="block max-w-full sm:mx-auto w-auto h-auto" alt={item.alt} />
						</li>
					))}
				</ul>
			</div>
		</>
	)
}

export default memo(Badges)