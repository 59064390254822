import { memo } from 'react'
// import { Line } from 'rc-progress'
import "react-step-progress-bar/styles.css"
import { ProgressBar } from "react-step-progress-bar"

const CustomProgressBar = (props) => {

	const { percent, height } = props
	return (
		<div className='relative w-full h-[32px] progressBarBox'>

			<ProgressBar percent={percent} filledBackground="#051236" height={height} />

			<div className='text-xs font-medium !text-white' style={{ textAlign: "center", width: `${percent}%`, position: 'absolute', top: '47%', transform: 'translateY(-45%)', color: '#000' }}>
				{`${percent}%`}
			</div>
		</div>
	)
}

export default memo(CustomProgressBar)